import SubMenu from "./SubMenu";

import IconBearings from "@/components/Icon/IconBearings.svg";
import IconBody from "@/components/Icon/IconBody.svg";
import IconBrakes from "@/components/Icon/IconBrakes.svg";
import IconChain from "@/components/Icon/IconChain.svg";
import IconClutch from "@/components/Icon/IconClutch.svg";
import IconElectrical from "@/components/Icon/IconElectrical.svg";
import IconEngine from "@/components/Icon/IconEngine.svg";
import IconExhaust from "@/components/Icon/IconExhaust.svg";
import IconFluids from "@/components/Icon/IconFluids.svg";
import IconFootControls from "@/components/Icon/IconFootControls.svg";
import IconHandControls from "@/components/Icon/IconHandControls.svg";
import IconLighting from "@/components/Icon/IconLighting.svg";
import IconLuggage from "@/components/Icon/IconLuggage.svg";
import IconMirrors from "@/components/Icon/IconMirrors.svg";
import IconPartsProtection from "@/components/Icon/IconPartsProtection.svg";
import IconPlastics from "@/components/Icon/IconPlastics.svg";
import IconSecurity from "@/components/Icon/IconSecurity.svg";
import IconService from "@/components/Icon/IconService.svg";
import IconStorage from "@/components/Icon/IconStorage.svg";
import IconTyres from "@/components/Icon/IconTyres.svg";

export default function Component({ open, onOpen, onClose, onCloseNavigation }) {
  const menu = {
    title: "Shop by Category",
    url: "/accessories",
    subMenu: [
      {
        title: "Bearings & Seals",
        url: "/accessories/bearings-and-seals",
        icon: IconBearings.src,
        subMenu: [
          {
            title: "Fork Seals",
            url: "/accessories/bearings-and-seals/fork-seals",
          },
          {
            title: "Linkage Kits",
            url: "/accessories/bearings-and-seals/linkage-kits",
          },
          {
            title: "Shocks",
            url: "/accessories/bearings-and-seals/shocks",
          },
          {
            title: "Steering",
            url: "/accessories/bearings-and-seals/steering",
          },
          {
            title: "Swingarm",
            url: "/accessories/bearings-and-seals/swingarm",
          },
        ],
      },
      {
        title: "Body",
        url: "/accessories/body",
        icon: IconBody.src,
        subMenu: [
          {
            title: "Blanking Plates & Frame Plugs",
            url: "/accessories/body/blanking-plates-frame-plugs",
          },
          {
            title: "Bolt Kits",
            url: "/accessories/body/bolt-kits",
          },
          {
            title: "Breather Vents",
            url: "/accessories/body/breather-vents",
          },
          {
            title: "Fairings",
            url: "/accessories/body/fairings",
          },
          {
            title: "Fender",
            url: "/accessories/body/fender",
          },
          {
            title: "Fluid Reservoirs & Caps",
            url: "/accessories/body/fluid-reservoirs-caps",
          },
          {
            title: "Fuel Caps",
            url: "/accessories/body/fuel-caps",
          },
          {
            title: "Screens & Shields",
            url: "/accessories/body/screens-shields",
          },
          {
            title: "Seats & Covers",
            url: "/accessories/body/seats-and-covers",
          },
          {
            title: "Swingarm Spools",
            url: "/accessories/body/swingarm-spools",
          },
          {
            title: "Tail Tidy",
            url: "/accessories/body/tail-tidy",
          },
        ],
      },
      {
        title: "Brakes",
        url: "/accessories/brakes",
        icon: IconBrakes.src,
        subMenu: [
          {
            title: "Brake Components",
            url: "/accessories/brakes/brake-components",
          },
          {
            title: "Brake Discs",
            url: "/accessories/brakes/brake-discs",
          },
          {
            title: "Brake Pads",
            url: "/accessories/brakes/brake-pads",
          },
        ],
      },
      {
        title: "Clutch",
        url: "/accessories/clutch",
        icon: IconClutch.src,
        subMenu: [
          {
            title: "Clutch Components",
            url: "/accessories/clutch/clutch-components",
          },
          {
            title: "Clutch Kits",
            url: "/accessories/clutch/clutch-kits",
          },
        ],
      },
      {
        title: "Drive",
        url: "/accessories/drive",
        icon: IconChain.src,
        subMenu: [
          {
            title: "Chain & Sprocket Kits",
            url: "/accessories/drive/chain-sprocket-kits",
          },
          {
            title: "Chain Adjusters",
            url: "/accessories/drive/chain-adjusters",
          },
          {
            title: "Chain Guides",
            url: "/accessories/drive/chain-guides",
          },
          {
            title: "Chain Rollers",
            url: "/accessories/drive/chain-rollers",
          },
          {
            title: "Chains",
            url: "/accessories/drive/chains",
          },
          {
            title: "Front Sprockets",
            url: "/accessories/drive/front-sprockets",
          },
          {
            title: "Rear Sprockets",
            url: "/accessories/drive/rear-sprockets",
          },
        ],
      },
      {
        title: "Electrical",
        url: "/accessories/electrical",
        icon: IconElectrical.src,
        subMenu: [
          {
            title: "Batteries",
            url: "/accessories/electrical/batteries",
          },
          {
            title: "Battery Chargers",
            url: "/accessories/electrical/battery-chargers",
          },
          {
            title: "Electrical Accessories",
            url: "/accessories/electrical/electrical-accessories",
          },
          {
            title: "Fuel & Ignition Tuning",
            url: "/accessories/electrical/fuel-and-ignition-tuning",
          },
          {
            title: "Hour Meters",
            url: "/accessories/electrical/hour-meters",
          },
          {
            title: "Starters",
            url: "/accessories/electrical/starters",
          },
          {
            title: "Switches",
            url: "/accessories/electrical/switches",
          },
        ],
      },
      {
        title: "Engine",
        url: "/accessories/engine",
        icon: IconEngine.src,
        subMenu: [
          {
            title: "Bearings & Seals",
            url: "/accessories/engine/bearings-and-seals",
          },
          {
            title: "Big Bore Kits",
            url: "/accessories/engine/big-bore-kits",
          },
          {
            title: "Camshafts",
            url: "/accessories/engine/camshafts",
          },
          {
            title: "Carburettor Parts",
            url: "/accessories/engine/carburettor-parts",
          },
          {
            title: "Conrods",
            url: "/accessories/engine/conrods",
          },
          {
            title: "Covers",
            url: "/accessories/engine/covers",
          },
          {
            title: "Crank Kits",
            url: "/accessories/engine/crank-kits",
          },
          {
            title: "Cylinders",
            url: "/accessories/engine/cylinders",
          },
          {
            title: "Engine Accessories",
            url: "/accessories/engine/engine-accessories",
          },
          {
            title: "Gasket Kits",
            url: "/accessories/engine/gasket-kits",
          },
          {
            title: "Hoses",
            url: "/accessories/engine/hoses",
          },
          {
            title: "Oil Filler Cap",
            url: "/accessories/engine/oil-filler-caps",
          },
          {
            title: "Performance Heads",
            url: "/accessories/engine/performance-heads",
          },
          {
            title: "Piston Kits",
            url: "/accessories/engine/piston-kits",
          },
          {
            title: "Spark Plugs",
            url: "/accessories/engine/spark-plugs",
          },
        ],
      },
      {
        title: "Exhausts & Performance",
        url: "/accessories/exhausts-and-performance",
        icon: IconExhaust.src,
        subMenu: [
          {
            title: "2 Stroke Pipes",
            url: "/accessories/exhausts-and-performance/2-stroke-pipes",
          },
          {
            title: "2 Stroke Silencers",
            url: "/accessories/exhausts-and-performance/2-stroke-silencers",
          },
          {
            title: "3/4 Exhausts",
            url: "/accessories/exhausts-and-performance/34-exhausts",
          },
          {
            title: "Baffles & Inserts",
            url: "/accessories/exhausts-and-performance/baffles-inserts",
          },
          {
            title: "Clamps & Hangers",
            url: "/accessories/exhausts-and-performance/clamps-hangers",
          },
          {
            title: "Exhaust Accessories",
            url: "/accessories/exhausts-and-performance/exhaust-accessories",
          },
          {
            title: "Exhaust Plugs",
            url: "/accessories/exhausts-and-performance/exhaust-plugs",
          },
          {
            title: "Exhaust Springs",
            url: "/accessories/exhausts-and-performance/exhaust-springs",
          },
          {
            title: "Full Exhaust Systems",
            url: "/accessories/exhausts-and-performance/full-exhaust-systems",
          },
          {
            title: "Headers",
            url: "/accessories/exhausts-and-performance/headers",
          },
          {
            title: "Link Mid Pipes",
            url: "/accessories/exhausts-and-performance/link-mid-pipes",
          },
          {
            title: "Power Commanders",
            url: "/accessories/exhausts-and-performance/power-commanders",
          },
          {
            title: "Slip On Exhausts",
            url: "/accessories/exhausts-and-performance/slip-on-exhausts",
          },
        ],
      },
      {
        title: "Fluids & Consumables",
        url: "/accessories/fluids-and-consumables",
        icon: IconFluids.src,
        subMenu: [
          {
            title: "2 Stroke Oil",
            url: "/accessories/fluids-and-consumables/2-stroke-oil",
          },
          {
            title: "4 Stroke Oil",
            url: "/accessories/fluids-and-consumables/4-stroke-oil",
          },
          {
            title: "Air Filter Oils",
            url: "/accessories/fluids-and-consumables/air-filter-oils",
          },
          {
            title: "Brake Fluid",
            url: "/accessories/fluids-and-consumables/brake-fluid",
          },
          {
            title: "Chain Lube & Clean",
            url: "/accessories/fluids-and-consumables/chain-lube-clean",
          },
          {
            title: "Coolants",
            url: "/accessories/fluids-and-consumables/coolants",
          },
          {
            title: "Fork Oil",
            url: "/accessories/fluids-and-consumables/fork-oil",
          },
          {
            title: "Gear Box Oil",
            url: "/accessories/fluids-and-consumables/gear-box-oil",
          },
          {
            title: "Maintenance & Service",
            url: "/accessories/fluids-and-consumables/maintenance-service",
          },
        ],
      },
      {
        title: "Foot Controls",
        url: "/accessories/foot-controls",
        icon: IconFootControls.src,
        subMenu: [
          {
            title: "Brake Pedals",
            url: "/accessories/foot-controls/brake-pedals",
          },
          {
            title: "Foot Peg Mounts",
            url: "/accessories/foot-controls/foot-peg-mounts",
          },
          {
            title: "Foot Pegs",
            url: "/accessories/foot-controls/foot-pegs",
          },
          {
            title: "Gear Levers",
            url: "/accessories/foot-controls/gear-levers",
          },
          {
            title: "Kick Start Lever",
            url: "/accessories/foot-controls/kick-start-lever",
          },
          {
            title: "Quick Shifters",
            url: "/accessories/foot-controls/quick-shifters",
          },
          {
            title: "Rear Sets",
            url: "/accessories/foot-controls/rear-sets",
          },
        ],
      },
      {
        title: "Hand Controls",
        url: "/accessories/hand-controls",
        icon: IconHandControls.src,
        subMenu: [
          {
            title: "Bar Clamps",
            url: "/accessories/hand-controls/bar-clamps",
          },
          {
            title: "Bar End Adaptors",
            url: "/accessories/hand-controls/bar-end-adaptors",
          },
          {
            title: "Bar Ends",
            url: "/accessories/hand-controls/bar-ends",
          },
          {
            title: "Bar Pads",
            url: "/accessories/hand-controls/bar-pads",
          },
          {
            title: "Clutch Cables",
            url: "/accessories/hand-controls/clutch-cables",
          },
          {
            title: "Grip Accessories",
            url: "/accessories/hand-controls/grip-accessories",
          },
          {
            title: "Grips",
            url: "/accessories/hand-controls/grips",
          },
          {
            title: "Handguards",
            url: "/accessories/hand-controls/handguards",
          },
          {
            title: "Handlebars, Mounts & Risers",
            url: "/accessories/hand-controls/handlebars-mounts-risers",
          },
          {
            title: "Heated Grips",
            url: "/accessories/hand-controls/heated-grips",
          },
          {
            title: "Levers",
            url: "/accessories/hand-controls/levers",
          },
          {
            title: "Throttle Cables",
            url: "/accessories/hand-controls/throttle-cables",
          },
          {
            title: "Throttle Kits",
            url: "/accessories/hand-controls/throttle-kits",
          },
          {
            title: "Triple Clamps",
            url: "/accessories/hand-controls/triple-clamps",
          },
        ],
      },
      {
        title: "Lighting & Electrical",
        url: "/accessories/lighting-and-electrical",
        icon: IconLighting.src,
        subMenu: [
          {
            title: "Bulbs",
            url: "/accessories/lighting-and-electrical/bulbs",
          },
          {
            title: "Headlights & Guards",
            url: "/accessories/lighting-and-electrical/headlights-and-guards",
          },
          {
            title: "Indicator Adaptors",
            url: "/accessories/lighting-and-electrical/indicator-adaptors",
          },
          {
            title: "Indicator Wiring Kits",
            url: "/accessories/lighting-and-electrical/indicator-wiring-kits",
          },
          {
            title: "Indicators",
            url: "/accessories/lighting-and-electrical/indicators",
          },
          {
            title: "Light Mounts",
            url: "/accessories/lighting-and-electrical/light-mounts",
          },
          {
            title: "Lighting Accessories",
            url: "/accessories/lighting-and-electrical/lighting-accessories",
          },
          {
            title: "Running & Drive Lights",
            url: "/accessories/lighting-and-electrical/running-and-drive-lights",
          },
          {
            title: "Tail & Brake Lights",
            url: "/accessories/lighting-and-electrical/tail-and-brake-lights",
          },
        ],
      },
      {
        title: "Luggage",
        url: "/accessories/luggage",
        icon: IconLuggage.src,
        subMenu: [
          {
            title: "Fitting Kits",
            url: "/accessories/luggage/fitting-kits",
          },
          {
            title: "Panniers",
            url: "/accessories/luggage/panniers",
          },
          {
            title: "Phone & GPS Mounts",
            url: "/accessories/luggage/phone-gps-mounts",
          },
          {
            title: "Racks",
            url: "/accessories/luggage/racks",
          },
          {
            title: "Saddlebags",
            url: "/accessories/luggage/saddlebags",
          },
          {
            title: "Tail Bags",
            url: "/accessories/luggage/tail-bags",
          },
          {
            title: "Tank Bags",
            url: "/accessories/luggage/tank-bags",
          },
          {
            title: "Top Boxes",
            url: "/accessories/luggage/top-boxes",
          },
          {
            title: "Backpacks",
            url: "/accessories/luggage/backpacks",
          },
          {
            title: "Gear Haulers",
            url: "/accessories/luggage/gear-haulers",
          },
        ],
      },
      {
        title: "Mirrors",
        url: "/accessories/mirrors",
        icon: IconMirrors.src,
        subMenu: [
          {
            title: "Mirror Adaptors",
            url: "/accessories/mirrors/mirror-adaptors",
          },
          {
            title: "Mirror Sets",
            url: "/accessories/mirrors/mirror-sets",
          },
          {
            title: "Single Mirrors",
            url: "/accessories/mirrors/single-mirrors",
          },
        ],
      },
      {
        title: "Plastics",
        url: "/accessories/plastics",
        icon: IconPlastics.src,
        subMenu: [
          {
            title: "Disc Guards",
            url: "/accessories/plastics/disc-guards",
          },
          {
            title: "Fender",
            url: "/accessories/plastics/fender",
          },
          {
            title: "Fork Guards",
            url: "/accessories/plastics/fork-guards",
          },
          {
            title: "Front Plates",
            url: "/accessories/plastics/front-plates",
          },
          {
            title: "Fuel Tanks",
            url: "/accessories/plastics/fuel-tanks",
          },
          {
            title: "Ignition Cover",
            url: "/accessories/plastics/ignition-cover",
          },
          {
            title: "Mud Flaps",
            url: "/accessories/plastics/mud-flaps",
          },
          {
            title: "Plastic Kits",
            url: "/accessories/plastics/plastics-kits",
          },
          {
            title: "Radiator Shrouds",
            url: "/accessories/plastics/radiator-shrouds",
          },
          {
            title: "Side Covers",
            url: "/accessories/plastics/side-covers",
          },
          {
            title: "Swingarm Sliders",
            url: "/accessories/plastics/swingarm-sliders",
          },
        ],
      },
      {
        title: "Protection",
        url: "/accessories/protection",
        icon: IconPartsProtection.src,
        subMenu: [
          {
            title: "Axle Sliders",
            url: "/accessories/protection/axle-sliders",
          },
          {
            title: "Bash Plates",
            url: "/accessories/protection/bash-plates",
          },
          {
            title: "Chain Guards",
            url: "/accessories/protection/chain-guards",
          },
          {
            title: "Crash Bars",
            url: "/accessories/protection/crash-bars",
          },
          {
            title: "Engine Covers",
            url: "/accessories/protection/engine-covers",
          },
          {
            title: "Exhaust Guards",
            url: "/accessories/protection/exhaust-guards",
          },
          {
            title: "Fork Protectors",
            url: "/accessories/protection/fork-protectors",
          },
          {
            title: "Frame Sliders",
            url: "/accessories/protection/frame-sliders",
          },
          {
            title: "Radiator Guards",
            url: "/accessories/protection/radiator-guards",
          },
          {
            title: "Sprocket Covers",
            url: "/accessories/protection/sprocket-covers",
          },
          {
            title: "Tail Sliders",
            url: "/accessories/protection/tail-sliders",
          },
          {
            title: "Tank Protectors and Pads",
            url: "/accessories/protection/tank-protectors-pads",
          },
          {
            title: "Dash Protectors",
            url: "/accessories/protection/dash-protectors",
          },
        ],
      },
      {
        title: "Security",
        url: "/accessories/security",
        icon: IconSecurity.src,
        subMenu: [
          {
            title: "Alarms",
            url: "/accessories/security/alarms",
          },
          {
            title: "Chains and Cables",
            url: "/accessories/security/chains-cables",
          },
          {
            title: "Disc Locks",
            url: "/accessories/security/disc-locks",
          },
          {
            title: "Helmet Locks",
            url: "/accessories/security/helmet-locks",
          },
          {
            title: "Locks",
            url: "/accessories/security/locks",
          },
        ],
      },
      {
        title: "Service & Maintenance",
        url: "/accessories/service-and-maintenance",
        icon: IconService.src,
        subMenu: [
          {
            title: "Air Box Covers",
            url: "/accessories/service-and-maintenance/air-box-covers",
          },
          {
            title: "Air Filters and Kits",
            url: "/accessories/service-and-maintenance/air-filters-kits",
          },
          {
            title: "Oil Filters and Kits",
            url: "/accessories/service-and-maintenance/oil-filter-kits",
          },
          {
            title: "Pick Ups",
            url: "/accessories/service-and-maintenance/pick-ups",
          },
          {
            title: "Stands",
            url: "/accessories/service-and-maintenance/stands",
          },
          {
            title: "Tools",
            url: "/accessories/service-and-maintenance/tools",
          },
        ],
      },
      {
        title: "Storage & Transport",
        url: "/accessories/storage-and-transport",
        icon: IconStorage.src,
        subMenu: [
          {
            title: "Bike Covers",
            url: "/accessories/storage-and-transport/bike-covers",
          },
          {
            title: "Fork Stands",
            url: "/accessories/storage-and-transport/fork-stands",
          },
          {
            title: "Ramps",
            url: "/accessories/storage-and-transport/ramps",
          },
          {
            title: "Stands",
            url: "/accessories/storage-and-transport/stands",
          },
          {
            title: "Storage",
            url: "/accessories/storage-and-transport/storage",
          },
          {
            title: "Tie Downs",
            url: "/accessories/storage-and-transport/tie-downs",
          },
          {
            title: "Wheel Chocks",
            url: "/accessories/storage-and-transport/wheel-chocks",
          },
          {
            title: "Workshop Mats",
            url: "/accessories/storage-and-transport/workshop-mats",
          },
        ],
      },
      {
        title: "Tyres & Wheels",
        url: "/accessories/tyres-and-wheels",
        icon: IconTyres.src,
        subMenu: [
          {
            title: "Tyre Pair Deals",
            url: "/accessories/tyres-and-wheels/tyre-pair-deals",
          },
          {
            title: "Adventure Tyres",
            url: "/accessories/tyres-and-wheels/adventure-tyres",
          },
          {
            title: "Axles and Spacers",
            url: "/accessories/tyres-and-wheels/axles-and-spacers",
          },
          {
            title: "Front Rims",
            url: "/accessories/tyres-and-wheels/front-rims",
          },
          {
            title: "Off-Road Tyres",
            url: "/accessories/tyres-and-wheels/offroad-tyres",
          },
          {
            title: "Quad Bike Tyres",
            url: "/accessories/tyres-and-wheels/quad-bike-tyres",
          },
          {
            title: "Rear Rims",
            url: "/accessories/tyres-and-wheels/rear-rims",
          },
          {
            title: "Rim Locks",
            url: "/accessories/tyres-and-wheels/rim-locks",
          },
          {
            title: "Road Tyres",
            url: "/accessories/tyres-and-wheels/road-tyres",
          },
          {
            title: "Scooter Tyres",
            url: "/accessories/tyres-and-wheels/scooter-tyres",
          },
          {
            title: "Training Wheels",
            url: "/accessories/tyres-and-wheels/training-wheels",
          },
          {
            title: "Tubes",
            url: "/accessories/tyres-and-wheels/tubes",
          },
          {
            title: "Tyre Warmers",
            url: "/accessories/tyres-and-wheels/tyre-warmers",
          },
          {
            title: "Wheel Bearing Kits",
            url: "/accessories/tyres-and-wheels/wheel-bearing-kits",
          },
          {
            title: "Wheel Hardware",
            url: "/accessories/tyres-and-wheels/wheel-hardware",
          },
          {
            title: "Wheel Kits",
            url: "/accessories/tyres-and-wheels/wheel-kits",
          },
          {
            title: "Wheel Tape",
            url: "/accessories/tyres-and-wheels/wheel-tape",
          },
          {
            title: "Race Tyres",
            url: "/accessories/tyres-and-wheels/race-tyres",
          },
          {
            title: "Gauges and Pumps",
            url: "/accessories/tyres-and-wheels/gauges-and-pumps",
          },
          {
            title: "Valve Stems",
            url: "/accessories/tyres-and-wheels/valve-stems",
          },
        ],
      },
    ],
  };
  return <SubMenu data={menu} open={open} onOpen={onOpen} onClose={onClose} onCloseNavigation={onCloseNavigation} />;
}
