"use client";
import classNames from "classnames";
import Image from "next/image";
import IconSearchWhite from "@/components/Icon/IconSearchWhite.svg";
import IconClose from "@/components/Icon/IconClose.svg";

import React, { useEffect, useRef, useState } from "react";
import { usePathname, useRouter } from "next/navigation";
import typesenseClient from "@/lib/typesense/client";
import SearchDialog from "../SearchDialog";

export default function SearchBox() {
  const searhBoxRef = useRef(null);
  const router = useRouter();
  const pathName = usePathname();
  const [searchValue, setSearchValue] = useState("");
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchDialog, setShowSearchDialog] = useState(false);

  const onKeyDown = e => {
    if (e.key === "Enter") {
      router.push(`/search?q=${encodeURIComponent(searchValue)}`);
      handleShowSearchResult(false);
    }
  };

  const fetchProductData = async searchValue => {
    const searchRequests = {
      searches: [
        {
          collection: "bikebiz-ecom",
          query_by: "title, variant_sku_list",
          q: searchValue,
          filter_by: "isPublished:true",
          per_page: 100,
          min_len_1typo: 3,
          min_len_2typo: 5,
        },
        {
          collection: "bikebiz-motorcycles",
          query_by: "product_name",
          q: searchValue,
          filter_by: "is_published:true",
          per_page: 100,
          min_len_1typo: 3,
          min_len_2typo: 5,
        },
      ],
    };
    const response = await typesenseClient.multiSearch.perform(searchRequests);
    if (response && response.results && response.results.length > 0) {
      const filteredData = [];
      const ecomData = response.results[0].hits.map(hit => hit.document);
      const vehicleData = response.results[1].hits.map(hit => hit.document);
      if (ecomData && ecomData.length > 0) {
        filteredData.push(
          ...ecomData.map(product => {
            return {
              name: product.title,
              slug: `/products/${product.url_key}`,
              type: "department",
              variant_sku_list: product.variant_sku_list || [],
            };
          })
        );
      }
      if (vehicleData && vehicleData.length > 0) {
        filteredData.push(
          ...vehicleData.map(product => {
            var urlProduct = "";
            if (product.type === "new_bike")
              if (product.product_range_slugs && product.product_range_slugs.length > 0) {
                urlProduct = `/new-bikes/${product.manufacturer.toLowerCase()}/${product.product_range_slugs[0]}/${
                  product.prismic_uid
                }`;
              } else {
                urlProduct = `/new-bikes/${product.manufacturer.toLowerCase()}/${product.prismic_uid}`;
              }
            if (product.type === "used_bike") urlProduct = `/used-bikes/${product.prismic_uid}`;
            return {
              name: product.product_name,
              slug: urlProduct,
              type: "vehicle",
              variant_sku_list: [],
            };
          })
        );
      }
      filteredData.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      setSearchResults(filteredData);
    }
  };

  const handleInputChange = value => {
    setSearchValue(value);

    if (value !== "") {
      setShowSearchResult(true);
      fetchProductData(value);
    } else {
      setSearchResults([]);
    }
  };

  const handleShowSearchResult = isShow => {
    setShowSearchResult(isShow);
    if (!isShow) {
      setSearchValue("");
      setSearchResults([]);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (searhBoxRef.current && !searhBoxRef.current.contains(event.target)) {
        setShowSearchResult(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (pathName.includes("/checkout")) {
      document.getElementById("search-box").style.opacity = "0";
      setShowSearchResult(false);
      setSearchValue("");
      setSearchResults([]);
    } else {
      document.getElementById("search-box").style.opacity = "1";
    }
  }, [pathName]);
  return (
    <>
      <div
        id="search-box"
        ref={searhBoxRef}
        className={classNames(
          "h-fit w-4/12 min-w-[360px] hidden lg:flex items-center ml-[60px] xl:ml-[123px] border-[1px] border-grey py-[10px] px-[18px] rounded-sm relative",
          {
            "bg-white": showSearchResult,
          }
        )}
      >
        <input
          placeholder="Search Bikebiz"
          className="w-full bg-transparent outline-none"
          value={searchValue}
          onChange={e => handleInputChange(e.target.value)}
          onClick={() => handleShowSearchResult(true)}
          onKeyDown={e => onKeyDown(e)}
        />
        {showSearchResult ? (
          <Image
            src={IconClose.src}
            alt="IconClose"
            width={20}
            height={20}
            className="cursor-pointer"
            onClick={() => handleShowSearchResult(false)}
          />
        ) : (
          <Image src={IconSearchWhite.src} alt="IconSearchWhite" width={24} height={24} className="text-white" />
        )}
        {showSearchResult && searchResults.length > 0 && (
          <div className="w-[446px] h-fit absolute top-[64px] left-0 bg-white shadow-xl z-30">
            <div className="w-[446px] max-h-[360px]  overflow-y-auto">
              {searchResults.map((result, index) => {
                const searchBySku = result.variant_sku_list.find(sku => sku.includes(searchValue));
                return (
                  <div
                    key={`search-result-${index}`}
                    onClick={() => {
                      router.push(result.slug);
                      handleShowSearchResult(false);
                    }}
                    className="cursor-pointer"
                  >
                    <div className="flex items-center gap-3 py-2 px-8 my-1 bg-white hover:bg-grey-100">
                      <div className="text-sm w-[380px] whitespace-nowrap overflow-hidden text-ellipsis">
                        {searchBySku ? (
                          <>
                            <b>{result.name}</b> ({searchBySku})
                          </>
                        ) : (
                          <>
                            {searchValue}
                            <b>
                              {result.name &&
                                result.name.substring(result.name.indexOf(searchValue) + searchValue.length + 1)}
                            </b>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="flex items-center gap-3 py-2 px-8 my-6 bg-white">
              <div
                onClick={() => {
                  router.push(`/search?q=${encodeURIComponent(searchValue)}`);
                  handleShowSearchResult(false);
                }}
              >
                <div className="text-sm underline cursor-pointer">View all results for ‘{searchValue}’</div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="block lg:hidden cursor-pointer right-[100px] md:right-[112px] ml-auto">
        <Image
          src={IconSearchWhite.src}
          alt="IconSearchWhite"
          width={18}
          height={18}
          className="text-white"
          onClick={() => setShowSearchDialog(true)}
        />
      </div>
      {showSearchResult && (
        <div
          className="w-screen absolute top-[80px] left-0 z-20"
          style={{ height: "calc(100vh - 80px)" }}
          onClick={() => handleShowSearchResult(false)}
        >
          <div className="w-full h-full opacity-50 bg-black"></div>
        </div>
      )}
      <SearchDialog open={showSearchDialog} onChange={data => setShowSearchDialog(data)} />
    </>
  );
}
